import { createGlobalStyle } from 'styled-components';
import COLORS from './colors';
import './fonts/style.css';
import 'react-table/react-table.css';
import media from './screenSize';

// import 'tableStyle/style.css'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Proxima Nova', sans-serif;
  }
  body {
    color: ${COLORS.black};
    overflow: hidden;
  }

  .Toastify__toast--success {
    background: ${COLORS.grey} !important;
  }
  .Toastify__toast--error {
    background: ${COLORS.red} !important;
  }

  .rap-popper-div {
    background: #f6f6f6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    max-width: 150px;
  }

  ${media.phablet`
    body {
      overflow: visible;
    }
  `}
`;

export default GlobalStyle;

export { default as COLORS } from './colors';
export { default as media } from './screenSize';
export { default as Button } from './Button.js';
export { default as Input } from './Input.js';
export { default as Text } from './Text.js';
export { default as Spacer } from './Spacer.js';
export { default as Header } from './Header.js';
export { default as Table } from './Table';
export { default as Wrapper } from './Wrapper.js';
export { default as Menu } from './Menu.js';
export { default as Modal } from './Modal.js';
export { default as Dropdown } from './Dropdown.js';
export { default as TextArea } from './TextArea.js';
export { default as Card } from './Card.js';
export { default as Sidebar } from './Sidebar.js';
export { default as Icon } from './Icon.js';
export { default as SidebarWrapper } from './SidebarWrapper.js';
export { default as Pagination } from './Pagination.js';
export { default as Segment } from './Segment.js';
export { default as StyledTable } from './StyledTable.js';
export { default as StyledSlideDown } from './StyledSlideDown.js';
export { default as Expand } from './StyledTable.js';
export { default as FormWrapper } from './FormWrapper.js';
export { default as Form } from './Form.js';
export { default as FileLabel } from './FileLabel';
export { Content } from './PoseSlideDown';
export { default as ExpanderSpan } from './ExpanderSpan';
export { default as StyledBullet } from './StyledBullet';
export { SearchIcon, CloseIcon } from './SearchIcons.js';
export { dotOne, dotTwo, dotThree } from './loader.js';
export { default as FlexBox } from './FlexBox.js';
export { default as FileUpload } from './FileUpload';
export { default as SendEmailForm } from './SendEmailForm';
