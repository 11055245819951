import React from 'react';
import styled from 'styled-components';
import COLORS from './colors';

const Wrapper = styled.div`
  width: ${props => (props.half ? '50%' : '100%')}
  display: ${props => (props.half ? 'inline-block' : 'block')}
  margin: ${props => (props.half ? '10px 0px' : '0px 0px')}
  position: relative;
`;

const Input = styled.input`
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid ${COLORS.lightGrey};
  width: ${props => (props.half ? '90%' : '100%')};
  margin-left: ${props => (props.half ? '5%' : '0%')};
  background-color: ${props => (props.form ? COLORS.lightGrey : 'white')}
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export default props => {
  return (
    <Wrapper {...props}>
      <Input {...props} />
      {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
    </Wrapper>
  );
};
