import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import EditTrackForm from './EditTrackForm';
import ReactTooltip from 'react-tooltip';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Icon,
  Text,
  Spacer,
  COLORS,
  FlexBox,
  Button,
  FormWrapper,
  Form,
  FileUpload,
  StyledSlideDown
} from 'components';
import {
  http,
  addTrackToManualSetlist,
  removeTrackFromManualSetlist,
  copyAllTracksToBooking,
  duplicateTrack
} from 'data-graphql';
import {
  faExchangeAlt,
  faPlus,
  faEllipsisH,
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons';
// import smoothscroll from 'smoothscroll-polyfill';
import { windowSize, isMTI } from 'helpers';

// kick off the polyfill!
// smoothscroll.polyfill();

const StyledProgress = styled(Progress)`
  .react-sweet-progress-line-inner {
    height: 15px;
    background-color: ${isMTI ? COLORS.darkBlue : COLORS.dark} !important;
  }
`;

const ComboTableWrapper = styled.div`
  .track-enter {
    opacity: 0.1;
    height: 0px
  }

  .track-enter.track-enter-active {
    opacity: 1;
    height: auto;
    transition: all 1000ms ease;
  }

  .track-leave {
    opacity: 1;
    height: auto
  }

  .track-leave.track-leave-active {
    opacity: 0.1;
    height: 0px;
    transition: all 1000ms ease;
  }
  .match,
  .mainMatch {
    outline: 2px solid ${COLORS.yellow} ;
  }

  .disabled {
    background: #dedede
    cursor: not-allowed;
    pointer-events: none
  }

  .mainFlex.disabled {
    opacity: 0.6
  }

  .match.mainList {
    outline: 2px solid ${isMTI ? COLORS.darkBlue : COLORS.dark} ;
  }
  .options {
    cursor: pointer
    &:hover {
      background-color: ${COLORS.hoverYellow};
    }
  }
  .react-audio-player {
    height: 30px
    width: 90%
    &:focus, &:active {
      outline: none;
    }
  }
  svg {
    color: ${COLORS.grey}
    &:hover {
      color: black
    }
  }
  .mainFlex div {
    align-self: center
  }
`;

const FlexButton = styled(Button)`
  margin: auto;
  padding: 5px 7px;
  &:hover {
    background-color: ${COLORS.hoverYellow};
  }
`;

const LinkText = styled(Text)`
  padding: 10px;
  background-color: ${isMTI ? '#2096f336' : '#6e14051a'};
  color: ${isMTI ? COLORS.darkBlue : COLORS.dark};
  cursor: pointer;
  &:hover {
    background-color: ${isMTI ? '#2096f336' : '#6e14051a'};
  }
`;

const TrackTableWrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: scroll;
  width: 100%;
  margin: auto;
`;
export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      main_filter: false,
      other_filter: false,
      view: false,
      columnExpander: '',
      selected: '',
      addCustomOpen: false,
      generating: false,
      generateProgress: 0,
      generateTotal: 0,
      generationFailed: false,
    };
  }

  componentDidMount() {
    this.getTracks();
  }

  getTracks = async () => {
    http()
      .get(`/tracks/management-info?bookingId=${this.props.booking.id}`)
      .then(response => {
        if (response.success) {
          const main = response.result.mainTracks.map(track => {
            const cues = track.cue_number.split('-');
            track.cue_start = cues[0] ? cues[0] : track.cue_number;
            track.cue_end = cues[1] ? cues[1] : track.cue_number;
            return track;
          });
          const other = response.result.otherTracks.map(track => {
            const cues = track.cue_number.split('-');
            track.cue_start = cues[0] ? cues[0] : track.cue_number;
            track.cue_end = cues[1] ? cues[1] : track.cue_number;
            return track;
          });
          const alt_setlists = _.uniqBy(other, 'setlist_id').map(track => {
            return {
              name: track.setlist_name,
              type: track.setlist_type,
              id: track.setlist_id
            };
          });

          const setlists = _.uniqBy(main, 'parent_setlist_name').map(track => {
            return {
              name: track.parent_setlist_name,
              type: track.parent_setlist_type,
              id: track.parent_setlist_id,
              key: Math.floor(Math.random() * 100) + track.parent_setlist_name
            };
          });

          const otherTracks =
            this.state.other_filter && this.state.other_filter !== false
              ? other.filter(
                  track => track.setlist_name === this.state.other_filter.name
                )
              : other;

          this.setState({
            main_tracks: main,
            other_tracks: otherTracks,
            initial_main_tracks: main,
            initial_other_tracks: other,
            other_setlists: alt_setlists,
            main_setlists: setlists
          });
        }
      });
  };

  addTrackToMain = async track => {
    // const main_matches = this.state.main_tracks.filter(
    //   main_track =>
    //     (main_track.original_track_id !== null &&
    //       main_track.original_track_id === track.original_track_id) ||
    //     main_track.id === track.original_track_id
    // );
    // if (main_matches) {
    //   await Promise.all(
    //     main_matches.map(async match => {
    //       const VARS = {
    //         booking_id: this.props.booking.id,
    //         track_id: match.id
    //       };
    //       const REQ_OBJECT = `{
    //         code
    //         success
    //       }`;

    //       await removeTrackFromManualSetlist(VARS, REQ_OBJECT);
    //     })
    //   );
    // }
    const VARS = {
      booking_id: this.props.booking.id,
      track_id: track.id
    };
    const REQ_OBJECT = `{
      code
      success
    }`;
    await addTrackToManualSetlist(VARS, REQ_OBJECT);
    await this.resetMatches();
    await this.getTracks();
  };

  swapTracks = async () => {
    await Promise.all(
      this.state.main_tracks.map(async track => {
        if (track.match) {
          const VARS = {
            booking_id: this.props.booking.id,
            track_id: track.id
          };
          const REQ_OBJECT = `{
            code
            success
          }`;
          return await removeTrackFromManualSetlist(VARS, REQ_OBJECT);
        } else {
          return false;
        }
      })
    );
    await Promise.all(
      this.state.other_tracks.map(async track => {
        if (track.match) {
          const VARS = {
            booking_id: this.props.booking.id,
            track_id: track.id
          };
          const REQ_OBJECT = `{
            code
            success
          }`;
          return await addTrackToManualSetlist(VARS, REQ_OBJECT);
        } else {
          return false;
        }
      })
    );
    await this.resetMatches();
    await this.getTracks();
  };

  removeTrack = async e => {
    const VARS = {
      booking_id: this.props.booking.id,
      track_id: e.target.dataset.track
    };
    const REQ_OBJECT = `{
      code
      success
    }`;

    await removeTrackFromManualSetlist(VARS, REQ_OBJECT);

    await this.resetMatches();
    await this.getTracks();
  };

  duplicate = async track => {
    const VARS = {
      booking_id: this.props.booking.id,
      track_id: track.id,
      setlist_id: track.setlist_id || track.parent_setlist_id
    };
    const REQ_OBJECT = `{
      code
      success
    }`;

    await duplicateTrack(VARS, REQ_OBJECT);

    // await this.resetMatches();
    // await this.getTracks();
  };

  addAllSetlistTracks = async () => {
    const VARS = {
      booking_id: this.props.booking.id,
      setlist_id: this.state.other_filter.id
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await copyAllTracksToBooking(VARS, REQ_OBJECT);
    if (RESULT.data.success) {
      this.getTracks();
    } else {
      toast.error(RESULT.data.message);
    }
  };

  resetMatches = () => {
    const match = this.state.main_tracks.map(item => {
      item.match === true ? (item.match = false) : (item.match = false);
      item.swapMatch && (item.swapMatch = false);
      item.mainMatch && (item.mainMatch = false);
      return item;
    });
    const match2 = this.state.other_tracks.map(item => {
      item.match === true ? (item.match = false) : (item.match = false);
      item.mainMatch && (item.mainMatch = false);
      return item;
    });

    this.setState({
      main_tracks: match,
      other_tracks: match2,
      track: undefined
    });
  };

  resetMain = e => {
    const currentTrack = this.state.main_tracks.filter(
      track => track.id === e.target.dataset.track
    )[0];
    this.setState({ track: currentTrack || undefined }, () => {
      const match = this.state.main_tracks.map(item => {
        item.match === true ? (item.match = false) : (item.match = false);
        item.swapMatch && (item.swapMatch = false);
        item.mainMatch && (item.mainMatch = false);
        return item;
      });
      const match2 = this.state.other_tracks.map(item => {
        item.match === true ? (item.match = false) : (item.match = false);
        item.swapMatch && (item.swapMatch = false);
        item.mainMatch && (item.mainMatch = false);
        return item;
      });

      this.setState(
        {
          main_tracks: match,
          other_tracks: match2
        },
        this.mainTracksMatch
      );
    });
  };

  mainTracksMatch = e => {
    this.resetMatches();

    const currentTrack = this.state.main_tracks.filter(
      track => track.id === e.target.dataset.track
    )[0];
    if (!currentTrack) return false;
    let matchCheck = this.state.main_tracks.map(item => {
      (item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
      (item.cue_end <= currentTrack.cue_end &&
        item.cue_end >= currentTrack.cue_start)
        ? (item.match = true)
        : (item.match = false);
      if (currentTrack === item) {
        item.mainMatch = true;
        item.match = false;
      }
      return item;
    });

    const matchCheck2 = this.state.other_tracks.map(item => {
      (item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
      (item.cue_end <= currentTrack.cue_end &&
        item.cue_end >= currentTrack.cue_start)
        ? (item.match = true)
        : (item.match = false);
      if (currentTrack === item) {
        item.mainMatch = true;
        item.match = false;
      }
      return item;
    });

    const match = matchCheck2.filter(item => item.match);
    // eslint-disable-next-line
    for (let newMatch of match) {
      matchCheck = matchCheck.map(item => {
        (item.cue_start >= newMatch.cue_start &&
          item.cue_start <= newMatch.cue_end) ||
        (item.cue_end <= newMatch.cue_end && item.cue_end >= newMatch.cue_start)
          ? (item.match = true)
          : (item.match = false);
        if (currentTrack === item) {
          item.mainMatch = true;
          item.match = false;
        }
        return item;
      });
    }
    this.setState({
      main_tracks: matchCheck,
      other_tracks: matchCheck2
    });
  };

  otherTracksMatch = e => {
    this.resetMatches();
    const currentTrack = this.state.other_tracks.filter(
      track => track.id === e.target.dataset.track
    )[0];

    if (!currentTrack) return false;
    const matchCheck = this.state.main_tracks.map(item => {
      (item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
      (item.cue_end <= currentTrack.cue_end &&
        item.cue_end >= currentTrack.cue_start)
        ? (item.match = true)
        : (item.match = false);

      item.swapMatch = item.match ? true : false;

      return item;
    });
    const match = matchCheck.filter(item => item.match);

    let matchCheck2 = this.state.other_tracks.map(item => {
      currentTrack.id === item.id ||
      (((item.cue_start >= currentTrack.cue_start &&
        item.cue_start <= currentTrack.cue_end) ||
        (item.cue_end <= currentTrack.cue_end &&
          item.cue_end >= currentTrack.cue_start)) &&
        item.setlist_id === currentTrack.setlist_id)
        ? (item.match = true)
        : (item.match = false);

      return item;
    });
    // eslint-disable-next-line
    for (let newMatch of match) {
      matchCheck2 = matchCheck2.map(item => {
        currentTrack.id === item.id ||
        (((item.cue_start >= newMatch.cue_start &&
          item.cue_start <= newMatch.cue_end) ||
          (item.cue_end <= newMatch.cue_end &&
            item.cue_end >= newMatch.cue_start)) &&
          item.setlist_id === currentTrack.setlist_id)
          ? (item.match = true)
          : (item.match = false);
        return item;
      });
    }

    this.setState(
      {
        main_tracks: matchCheck,
        other_tracks: matchCheck2
      },
      this.handleScroll(currentTrack.cue_start)
    );
  };

  handleMainFilterChange = mainFilter => {
    const main_tracks = mainFilter
      ? this.state.main_tracks.filter(
          track => track.parent_setlist_name === mainFilter.name
        )
      : this.state.initial_main_tracks;
    this.setState({ main_filter: mainFilter, main_tracks: main_tracks });
  };

  handleOtherFilterChange = otherFilter => {
    const other_tracks = otherFilter
      ? this.state.initial_other_tracks.filter(
          track => track.setlist_name === otherFilter.name
        )
      : this.state.initial_other_tracks;
    this.setState({ other_filter: otherFilter, other_tracks: other_tracks });
  };

  completeGenerate = () => {
    this.setState({
      generating: false,
      generateProgress: 0,
      generateTotal: 0
    });
    this.getTracks();
  };

  checkProgress = async () => {
    const response = await http().get(
      `/tracks/management-info?bookingId=${this.props.booking.id}`
    );

    const remainingTracks = response.result.otherTracks.filter(
      track => track.setlist_type === 'custom' && !track.rehearsal_location
    );
    const customTracks = this.state.generateTotal - remainingTracks.length;
    let progress = (customTracks / this.state.generateTotal) * 100;
    progress =
      progress <= this.state.generateProgress &&
      this.state.generateProgress < 90
        ? this.state.generateProgress + 1
        : progress;
    this.setState(
      {
        generateProgress: progress
      },

      () => {
        if (this.state.generateProgress >= 100) {
          clearInterval(this.state.check);
          this.completeGenerate();
        }
      }
    );
  };

  generateRehearsalTracks = async () => {
    this.state.generationFailed = false
    let payload;
    const customTracksId = this.state.other_tracks.filter(
      track => track.setlist_type === 'custom' && !track.rehearsal_location
    );
    if (customTracksId.length > 0) {
      payload = { setlist_id: customTracksId[0].setlist_id };
    } else {
      toast.error('No custom tracks for this booking.');
      return false;
    }

    this.setState({
      generating: true,
      generateTotal: customTracksId.length
    });

    await http().post(`/tracks/generate-rehearsal`, payload);

    let check = setInterval(this.checkProgress, 2500);
    this.setState({ check: check });

    setTimeout(() => {
      clearInterval(this.state.check);
      // Display error message
      this.state.generationFailed = true
      this.state.generateProgress = 0
    }, 15 * 60 * 1000); // Fail after 15 minutes
  };

  openCustom = () => {
    this.setState({ addCustomOpen: !this.state.addCustomOpen });
  };

  handleChildChange = () => {
    this.getTracks();
  };

  openTrack = e => {
    const open_tracks = this.state.other_tracks.map(track => {
      track.editTrackOpen =
        track.id === e.target.dataset.track && !track.editTrackOpen
          ? true
          : false;
      return track;
    });
    this.setState({ other_tracks: open_tracks });
  };

  openMainTrack = e => {
    const open_main = this.state.main_tracks.map(track => {
      track.editTrackOpen =
        track.id === e.target.dataset.track && !track.editTrackOpen
          ? true
          : false;
      return track;
    });
    this.setState({ main_tracks: open_main });
  };

  close = () => {
    this.getTracks();
    this.setState({ addCustomOpen: false });
  };

  handleScroll = cue => {
    const divToScrollTo = document.getElementById(`${cue}`);
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  render() {
    const { booking } = this.props;
    if (!this.state.main_tracks) return null;
    return (
      <>
        {windowSize !== 'desktop' ? (
          <ComboTableWrapper>
            <Text large bold red>
              {booking.show.name}
            </Text>
            <Spacer size="20px" />
            <Text>
              To manage tracks, please visit the organization dashboard on a
              desktop computer.
            </Text>
            <Spacer size="25px" />
            <Text large>Active Playlist</Text>
            <Spacer size="20px" />
            <FlexBox style={{ width: '100%' }} direction="column">
              <FormWrapper
                style={{
                  padding: '8px 5px',
                  margin: '1px auto',
                  border: 'none',
                  boxShadow: 'none',
                  width: '95%',
                  backgroundColor: `${COLORS.darkRed}`,
                  color: 'white',
                  outline: `2px solid ${COLORS.darkRed}`
                }}
              >
                <FlexBox className="mainFlex" justify="space-between">
                  <div style={{ width: '20%' }}>Cue</div>
                  <div style={{ width: '80%', textAlign: 'left' }}>Track</div>
                </FlexBox>
              </FormWrapper>
              <TrackTableWrapper>
                <TransitionGroup className="main-track-list">
                  {this.state.main_tracks
                    .sort((a, b) =>
                      a.cue_number > b.cue_number
                        ? 1
                        : b.cue_number > a.cue_number
                        ? -1
                        : 0
                    )
                    .map(item => (
                      <CSSTransition
                        key={item.id}
                        timeout={500}
                        classNames="track"
                      >
                        <FormWrapper
                          key={item.id}
                          id={item.cue_start}
                          style={{
                            padding: '15px 10px',
                            margin: '2px auto',
                            border: 'none',
                            boxShadow: 'none',
                            width: '95%'
                          }}
                        >
                          <FlexBox className="mainFlex" justify="space-between">
                            <div style={{ width: '20%' }}>
                              <Text style={{ fontSize: '0.85em' }}>
                                {item.cue_number}
                              </Text>
                            </div>
                            <div style={{ width: '80%', textAlign: 'left' }}>
                              <Text style={{ fontSize: '0.85em' }}>
                                {item.track_name.length > 25
                                  ? item.track_name.substr(0, 15) +
                                    '... ' +
                                    item.track_name.substr(
                                      item.track_name.length - 10,
                                      item.track_name.length
                                    )
                                  : item.track_name}
                              </Text>
                            </div>
                          </FlexBox>
                        </FormWrapper>
                      </CSSTransition>
                    ))}
                </TransitionGroup>
              </TrackTableWrapper>
            </FlexBox>
          </ComboTableWrapper>
        ) : (
          <ComboTableWrapper>
            <Text large bold>
              {booking.show.name}
            </Text>
            <Button
              onClick={this.openCustom}
              style={{ verticalAlign: '40%', marginLeft: 50 }}
            >
              Add Custom Tracks
            </Button>
            <Button
              onClick={this.generateRehearsalTracks}
              style={{ verticalAlign: '40%', marginLeft: 50 }}
            >
              Generate Rehearsal Tracks
            </Button>

            <Spacer size="30px" />
            <StyledSlideDown>
              {this.state.addCustomOpen && (
                <FileUpload
                  show={booking.show}
                  setlist={booking.custom_setlist.id}
                  booking={booking.id}
                  onChange={this.close}
                />
              )}
            </StyledSlideDown>
            <StyledSlideDown>
              {this.state.generating && (
                <FormWrapper>
                  {this.state.generationFailed
                    ? <Text large bold>Generation failed</Text>
                    : <StyledProgress percent={this.state.generateProgress}/>}
                </FormWrapper>
              )}
            </StyledSlideDown>
            <Spacer size="30px" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'top',
                flexWrap: 'wrap'
              }}
            >
              <div style={{ width: '48%' }}>
                <Text red large>
                  Active Playlist
                </Text>
              </div>
              <FlexBox
                direction="row"
                justify="space-between"
                style={{ width: '48%' }}
              >
                <Text red large>
                  Other Setlists
                </Text>
                {this.state.other_filter && (
                  <LinkText onClick={this.addAllSetlistTracks}>
                    + Add all {this.state.other_filter.name} tracks to playlist{' '}
                  </LinkText>
                )}
              </FlexBox>
              <Spacer size="10px" />
              <FlexBox style={{ width: '50%' }} direction="column">
                <FormWrapper
                  style={{
                    padding: '8px 5px',
                    margin: '1px auto',
                    border: 'none',
                    boxShadow: 'none',
                    width: '96%',
                    backgroundColor: `${COLORS.darkRed}`,
                    color: 'white',
                    outline: `2px solid ${COLORS.darkRed}`
                  }}
                >
                  <FlexBox className="mainFlex" justify="space-between">
                    <div style={{ width: '10%' }}>Cue</div>
                    <div
                      style={{
                        width: '36%',
                        textAlign: 'left',
                        paddingLeft: '5px'
                      }}
                    >
                      Track
                    </div>
                    <div style={{ width: '7%' }}>RT</div>
                    <div style={{ width: '7%' }}>PT</div>
                    <div style={{ width: '7%' }}>MID</div>
                    <div style={{ width: '27%' }}>
                      Setlist
                      {/* <Form.StyledSelect
                    value={this.state.main_filter}
                    onChange={this.handleMainFilterChange}
                    options={this.state.main_setlists}
                    isSearchable={false}
                    isClearable={true}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.name}
                    placeholder="Setlist"
                    styles={Form.CustomStyles}
                  /> */}
                    </div>
                    <div style={{ width: '6%' }} />
                  </FlexBox>
                </FormWrapper>
                <TrackTableWrapper>
                  <TransitionGroup className="main-track-list">
                    {this.state.main_tracks
                      .sort((a, b) =>
                        a.cue_number > b.cue_number
                          ? 1
                          : b.cue_number > a.cue_number
                          ? -1
                          : 0
                      )
                      .map(item => (
                        <CSSTransition
                          key={item.id}
                          timeout={500}
                          classNames="track"
                        >
                          <FormWrapper
                            key={item.id}
                            id={item.cue_start}
                            className={
                              item.match
                                ? 'match mainList'
                                : item.mainMatch
                                ? 'mainMatch'
                                : ''
                            }
                            style={{
                              padding: '15px 10px',
                              margin: '2px auto',
                              border: 'none',
                              boxShadow: 'none',
                              width: '96%'
                            }}
                          >
                            <FlexBox
                              className="mainFlex"
                              justify="space-between"
                            >
                              <div style={{ width: '10%' }}>
                                {item.cue_number}
                              </div>

                              <div
                                style={{
                                  width: '35%',
                                  textAlign: 'left',
                                  paddingLeft: '5px'
                                }}
                              >
                                {item.track_name.length > 25
                                  ? item.track_name.substr(0, 15) +
                                    '... ' +
                                    item.track_name.substr(
                                      item.track_name.length - 10,
                                      item.track_name.length
                                    )
                                  : item.track_name}
                              </div>
                              <div
                                style={{
                                  textAlign: 'left',
                                  paddingLeft: '5px',
                                  width: '5%',
                                  cursor: 'pointer'
                                }}
                              >
                                {item.parent_setlist_type === 'custom' && (
                                  <Icon
                                    style={{
                                      padding: '4px'
                                    }}
                                    name="pencil-1"
                                    data-track={item.id}
                                    onClick={this.openMainTrack}
                                  />
                                )}
                              </div>
                              <div style={{ width: '7%' }}>
                                {item.rehearsal_location && (
                                  <FontAwesomeIcon
                                    style={{ color: '#333' }}
                                    icon={faCheckSquare}
                                  />
                                )}
                              </div>
                              <div style={{ width: '7%' }}>
                                {item.production_location && (
                                  <FontAwesomeIcon
                                    style={{ color: '#333' }}
                                    icon={faCheckSquare}
                                  />
                                )}
                              </div>
                              <div style={{ width: '7%' }}>
                                {item.midi_location && (
                                  <FontAwesomeIcon
                                    style={{ color: '#333' }}
                                    icon={faCheckSquare}
                                  />
                                )}
                              </div>
                              <div style={{ width: '25%' }}>
                                <Text>{item.parent_setlist_name}</Text>
                              </div>
                              <div
                                style={{
                                  width: '10%',
                                  padding: '5px',
                                  backgroundColor: 'white'
                                }}
                              >
                                {!item.match && (
                                  <>
                                    <FlexButton
                                      black
                                      data-tip="Remove from main setlist"
                                      style={{ padding: '0px' }}
                                    >
                                      <Icon
                                        data-track={item.id}
                                        onClick={this.removeTrack}
                                        name="arrow-right-1"
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          padding: '5px 7px'
                                        }}
                                      />
                                    </FlexButton>
                                    {/* <FlexButton style={{ marginTop: '5px' }}>
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        data-tip="Duplicate Track"
                                        data-track={item.id}
                                        // I know our preference is against this - nature of the icon means occasional click registers on svg rather than icon does not pass data via props well
                                        onClick={() => {
                                          this.duplicate(item);
                                        }}
                                        style={{
                                          alignSelf: 'center',
                                          cursor: 'pointer'
                                        }}
                                      />
                                      <ReactTooltip
                                        place="bottom"
                                        type={isMTI ? 'info' : 'warn'}
                                        effect="solid"
                                        multiline={true}
                                      />
                                    </FlexButton> */}
                                  </>
                                )}
                                {item.match && (
                                  <Text small red>
                                    Track will be replaced
                                  </Text>
                                )}
                              </div>
                            </FlexBox>
                            <Spacer size="10px" />
                            <Spacer size="10px" />
                            <StyledSlideDown>
                              {item.editTrackOpen && (
                                <>
                                  <Spacer size="10px" />
                                  <EditTrackForm
                                    track={item}
                                    custom={
                                      item.parent_setlist_type === 'custom'
                                    }
                                    onChange={this.handleChildChange}
                                  />
                                </>
                              )}
                            </StyledSlideDown>
                          </FormWrapper>
                        </CSSTransition>
                      ))}
                  </TransitionGroup>
                </TrackTableWrapper>
              </FlexBox>
              <FlexBox style={{ width: '50%' }} direction="column">
                <FormWrapper
                  style={{
                    padding: '8px 5px',
                    margin: '1px auto',
                    border: 'none',
                    boxShadow: 'none',
                    width: '96%',
                    backgroundColor: `${COLORS.darkRed}`,
                    color: 'white',
                    outline: `2px solid ${COLORS.darkRed}`
                  }}
                >
                  <FlexBox className="mainFlex" justify="space-between">
                    <div style={{ width: '6%' }} />
                    <div style={{ width: '10%' }}>Cue</div>

                    <div
                      style={{
                        width: '40%',
                        textAlign: 'left',
                        paddingLeft: '5px'
                      }}
                    >
                      Track
                    </div>
                    <div style={{ width: '7%' }}>RT</div>
                    <div style={{ width: '7%' }}>PT</div>
                    <div style={{ width: '7%' }}>MID</div>
                    <div style={{ width: '30%' }}>
                      <Form.StyledSelect
                        value={this.state.other_filter}
                        onChange={this.handleOtherFilterChange}
                        options={this.state.other_setlists}
                        isSearchable={false}
                        isClearable={true}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.name}
                        placeholder="Setlist"
                        styles={Form.CustomStyles}
                      />
                    </div>
                  </FlexBox>
                </FormWrapper>

                <TrackTableWrapper>
                  <TransitionGroup className="other-track-list">
                    {this.state.other_tracks
                      .sort((a, b) =>
                        a.cue_number > b.cue_number
                          ? 1
                          : b.cue_number > a.cue_number
                          ? -1
                          : 0
                      )
                      .map(item => {
                        return (
                          <CSSTransition
                            key={item.id}
                            timeout={500}
                            classNames="track"
                          >
                            <FormWrapper
                              key={item.id}
                              style={{
                                padding: '15px 10px',
                                margin: '2px auto',
                                border: 'none',
                                boxShadow: 'none',
                                width: '96%'
                              }}
                              className={`${item.match ? 'match' : ''} ${
                                item.is_main ? 'disabled' : ''
                              }`}
                              data-tip={`${
                                item.is_main ? 'Already in Playlist' : ''
                              }`}
                            >
                              {item.is_main && (
                                <Text red>In Active Playlist</Text>
                              )}
                              <FlexBox
                                justify="space-between"
                                style={{ alignContent: 'center' }}
                                className={`mainFlex  ${
                                  item.is_main ? 'disabled' : ''
                                }`}
                              >
                                <div
                                  style={{
                                    padding: '5px',
                                    width: !item.match ? '6%' : '20%'
                                  }}
                                  data-track={item.id}
                                >
                                  {!item.match && (
                                    <>
                                      <FlexButton
                                        black
                                        data-track={item.id}
                                        style={{ padding: '0px' }}
                                        onClick={this.otherTracksMatch}
                                        data-tip="See options to add Tracks"
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            color: 'white',
                                            width: '30px',
                                            height: '29px',
                                            padding: '5px 7px'
                                          }}
                                          data-track={item.id}
                                          onClick={this.otherTracksMatch}
                                          icon={faEllipsisH}
                                        />
                                      </FlexButton>
                                      <ReactTooltip
                                        place="bottom"
                                        type={isMTI ? 'info' : 'error'}
                                        effect="solid"
                                        multiline={true}
                                      />
                                    </>
                                  )}
                                  {item.match && (
                                    <FlexBox
                                      align="self-start"
                                      justify="space-between"
                                      style={{
                                        alignContent: 'center',
                                        padding: '10px',
                                        boxShadow:
                                          '1px 1px 2px 0px rgba(0,0,0,0.25)'
                                      }}
                                    >
                                      <div
                                        style={{
                                          cursor: 'pointer',
                                          padding: '0px 5px 5px 0px',
                                          fontSize: '10px'
                                        }}
                                      >
                                        <Icon
                                          onClick={this.resetMatches}
                                          name="close"
                                          data-tip="Close button panel"
                                          style={{
                                            fontWeight: '700',
                                            cursor: 'pointer'
                                          }}
                                        />
                                        <ReactTooltip
                                          place="bottom"
                                          type={isMTI ? 'info' : 'error'}
                                          effect="solid"
                                          multiline={true}
                                        />
                                      </div>
                                      <FontAwesomeIcon
                                        icon={faExchangeAlt}
                                        data-tip="Replace highlighted tracks"
                                        data-track={item.id}
                                        onClick={this.swapTracks}
                                        style={{
                                          alignSelf: 'center',
                                          cursor: 'pointer'
                                        }}
                                      />
                                      <ReactTooltip
                                        place="bottom"
                                        type={isMTI ? 'info' : 'error'}
                                        effect="solid"
                                        multiline={true}
                                      />
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        data-tip="Add Single Track"
                                        data-track={item.id}
                                        // I know our preference is against this - nature of the icon means occasional click registers on svg rather than icon does not pass data via props well
                                        onClick={() => {
                                          this.addTrackToMain(item);
                                        }}
                                        style={{
                                          alignSelf: 'center',
                                          cursor: 'pointer'
                                        }}
                                      />
                                      <ReactTooltip
                                        place="bottom"
                                        type={isMTI ? 'info' : 'warn'}
                                        effect="solid"
                                        multiline={true}
                                      />
                                    </FlexBox>
                                  )}
                                </div>
                                <div style={{ width: '10%' }}>
                                  {item.cue_number}
                                </div>

                                <div
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: '5px',
                                    width: '35%',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {item.track_name.length > 25
                                    ? item.track_name.substr(0, 15) +
                                      '... ' +
                                      item.track_name.substr(
                                        item.track_name.length - 10,
                                        item.track_name.length
                                      )
                                    : item.track_name}
                                </div>
                                <div
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: '5px',
                                    width: '5%',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {item.setlist_type === 'custom' && (
                                    <>
                                      <Icon
                                        style={{
                                          padding: '4px'
                                        }}
                                        data-tip="Edit track name and cue number"
                                        name="pencil-1"
                                        data-track={item.id}
                                        onClick={this.openTrack}
                                      />
                                      <ReactTooltip
                                        place="bottom"
                                        type={isMTI ? 'info' : 'error'}
                                        effect="solid"
                                        multiline={true}
                                      />
                                    </>
                                  )}
                                </div>
                                <div style={{ width: '7%' }}>
                                  {item.rehearsal_location && (
                                    <FontAwesomeIcon
                                      style={{ color: '#333' }}
                                      icon={faCheckSquare}
                                    />
                                  )}
                                </div>
                                <div style={{ width: '7%' }}>
                                  {item.production_location && (
                                    <FontAwesomeIcon
                                      style={{ color: '#333' }}
                                      icon={faCheckSquare}
                                    />
                                  )}
                                </div>
                                <div style={{ width: '7%' }}>
                                  {item.midi_location && (
                                    <FontAwesomeIcon
                                      style={{ color: '#333' }}
                                      icon={faCheckSquare}
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: item.match ? '20%' : '30%'
                                  }}
                                >
                                  {item.setlist_name}
                                </div>
                              </FlexBox>
                              <Spacer size="10px" />
                              <StyledSlideDown>
                                {item.editTrackOpen && (
                                  <>
                                    <Spacer size="10px" />
                                    <EditTrackForm
                                      custom={item.setlist_type === 'custom'}
                                      track={item}
                                      onChange={this.handleChildChange}
                                    />
                                  </>
                                )}
                              </StyledSlideDown>
                            </FormWrapper>
                          </CSSTransition>
                        );
                      })}
                  </TransitionGroup>
                </TrackTableWrapper>
              </FlexBox>
            </div>
          </ComboTableWrapper>
        )}
      </>
    );
  }
}
